import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from '../services/general.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private generalService:GeneralService,private router:Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
  
      today =  new Date(mm + '/' + dd + '/' + yyyy);
  
      console.log('hoy',today)
      console.log('loged',this.generalService.getDateLoged())

      if(!this.generalService.GetisLoggedIn() || today > this.generalService.getDateLoged() )
      {
        console.log('Por favor LogIn')
        this.router.navigate(['login'])
      }
    return this.generalService.GetisLoggedIn();
  }
}
  
