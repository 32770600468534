import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PipesModule } from './pipes/pipes.module';
import { ComponentesModule } from './componentes/componentes.module';
import { HttpClientModule } from '@angular/common/http';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { SignaturePadModule } from 'angular2-signaturepad'; 
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AgmCoreModule } from '@agm/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { AuthGuard } from './auth/auth.guard';
import { environment } from 'src/environments/environment';
 



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
      BrowserModule, 
      IonicModule.forRoot(), 
      AppRoutingModule,
      PipesModule,
      ComponentesModule,
      HttpClientModule,
      SignaturePadModule ,
      AgmCoreModule.forRoot({
        apiKey: 'AIzaSyC5Yy7o4kzCG8KKXiUZEC2jFHn-NMN0Rvc'
      }),
      // provideFirebaseApp(() => initializeApp(environment.firebaseConfig )),
      // provideFirestore(() => getFirestore()),
    ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    File,
    FileOpener,
    Geolocation,
    GoogleAnalytics, 
    AuthGuard,
  ], 
  bootstrap: [AppComponent]
})
export class AppModule {}
