import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroPipe } from './filtro.pipe';
import { Filtro3Pipe } from './filtro3.pipe';



@NgModule({
  declarations: [FiltroPipe, Filtro3Pipe],
  exports: [FiltroPipe,Filtro3Pipe],
})
export class PipesModule { }
