import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  { 
    path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orden-estado',
    loadChildren: () => import('./pages/orden-estado/orden-estado.module').then( m => m.OrdenEstadoPageModule),
        canActivate: [AuthGuard]
  },
  {
    path: 'orden-detalle',
    loadChildren: () => import('./pages/orden-detalle/orden-detalle.module').then( m => m.OrdenDetallePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reportecierre',
    loadChildren: () => import('./pages/reportecierre/reportecierre.module').then( m => m.ReportecierrePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'liquidaciones-lista',
    loadChildren: () => import('./pages/liquidaciones-lista/liquidaciones-lista.module').then( m => m.LiquidacionesListaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'crear-liquidacion',
    loadChildren: () => import('./pages/crear-liquidacion/crear-liquidacion.module').then( m => m.CrearLiquidacionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'confirmar-liquidacion',
    loadChildren: () => import('./pages/confirmar-liquidacion/confirmar-liquidacion.module').then( m => m.ConfirmarLiquidacionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ordencanceladas',
    loadChildren: () => import('./pages/ordencanceladas/ordencanceladas.module').then( m => m.OrdencanceladasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'listado',
    loadChildren: () => import('./pages/listado/listado.module').then( m => m.ListadoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reportedeliveries',
    loadChildren: () => import('./pages/reportedeliveries/reportedeliveries.module').then( m => m.ReportedeliveriesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reportependienteliquidar',
    loadChildren: () => import('./pages/reportependienteliquidar/reportependienteliquidar.module').then( m => m.ReportependienteliquidarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'misordenes',
    loadChildren: () => import('./pages/misordenes/misordenes.module').then( m => m.MisordenesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reporte-bpay',
    loadChildren: () => import('./pages/report-bpay/report-bpay.module').then( m => m.ReportBpayModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'misliquidaciones',
    loadChildren: () => import('./pages/misliquidaciones/misliquidaciones.module').then( m => m.MisliquidacionesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reportes',
    loadChildren: () => import('./pages/reportes/reportes.module').then( m => m.ReportesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'ordendemora',
    loadChildren: () => import('./pages/ordendemora/ordendemora.module').then( m => m.OrdendemoraPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash : true} )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
