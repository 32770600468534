// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL: 'https://restaurantapi.devcit.com:7022',
  // API_URL: 'http://a90d962d2a329444db723310d70b81b9-1271939869.us-east-1.elb.amazonaws.com', 
  // API_URL: 'http://a86103be0f74943ab894efe1db18ddbb-337078537.us-east-1.elb.amazonaws.com', 
  API_URL: 'https://restauranteapi.developereks.xyz', 
  //'https://localhost:44328',
  // API_URL: 'https://restaurantapi.bipbip.hn:7022',
  // API_Reporteria: 'https://reportrestaurantapi.devcit.com:7026',
  API_Reporteria: 'https://reportservice.bipbip.hn:7031',
  API_URL_DashBoard: 'http://localhost:51531',
  version: '1.0.0',
  brandId: null,
  firebaseConfig: {
    apiKey: "AIzaS+yBDSHf5yQZ431eTjjdBn16MYsEwNm9-Lec",
    authDomain: "bip-bip-74692.firebaseapp.com",
    databaseURL: "https://bip-bip-74692.firebaseio.com",
    projectId: "bip-bip-74692",
    storageBucket: "bip-bip-74692.appspot.com",
    messagingSenderId: "283588208270",
    appId: "1:283588208270:web:aa68c9af6e7e06cb078bdf",
    measurementId: "G-Q9NQS6H58V"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
