<ion-app>
  <ion-split-pane when="md" contentId="principal">
    
    <!-- <app-menu></app-menu> -->
    <ion-menu   style="    max-width: 20%;"
      side="start" 
      contentId="principal"
      menuId="primerMenu">
      <ion-header >
        <ion-toolbar color="danger" >
          <div class="titleicon">
              <img width="90px" height="50px" src="assets/LOGO_BIP_BIP3.png" />
        </div>
          <ion-title style="color: white;" >Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content *ngIf="login" >
        <ion-list>

          <ng-container *ngFor="let c of componentes | async ">
            
            <ion-menu-toggle   autoHide = "false" >
            
              <ion-item  
                [routerLink]="c.redirectTo"
                >
              <ion-icon  slot="start"  [name]="c.icon"></ion-icon>
              <ion-label>{{c.name}}</ion-label>
              </ion-item>
  
            </ion-menu-toggle>

          </ng-container>
          <ng-container >
            
            <ion-menu-toggle   autoHide = "false" >
              <ion-item  
                (click)="logOut()" 
                >
              <ion-icon  slot="start"  name="power"></ion-icon>
              <ion-label>Cerrar Sesión</ion-label>
              </ion-item>
  
            </ion-menu-toggle>
          </ng-container>
          
       
        
          
        </ion-list>
      </ion-content>
      </ion-menu>

     
    <ion-router-outlet id="principal"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
