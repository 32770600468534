import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Componente } from './Interfaces/interfaces';
import { Observable } from 'rxjs';
import { GeneralService } from './services/general.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  componentes: Observable<Componente[]>;
  idRestaurante: number;
  baseOperacion: any;
  public login = false;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private dataServices: GeneralService,
    private ga: GoogleAnalytics,
    private router : Router,
    private route : ActivatedRoute,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.ga.startTrackerWithId('UA-179431527-1')
   .then(() => {
     console.log('Google analytics is ready now');
      this.ga.trackView('inicio de app');
     // El tracker esta activado.
     // Ahora puede rastrear páginas o configurar información adicional con AppVersion o UserId
   })
   .catch(e => console.log('Error Incializando GoogleAnalytics', e));
      
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // this.ga.startTrackerWithId('UA-178892872-1')
      // .then(() => {}).catch(e => alert('Error starting GoogleAnalytics == '+ e));

  
  
    });

    var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
  
      today =  new Date(mm + '/' + dd + '/' + yyyy);
  
      console.log('hoy',today)
      console.log('loged',this.dataServices.getDateLoged())

    if(!this.dataServices.GetisLoggedIn()  || today > this.dataServices.getDateLoged())
    {
      console.log('Por favor LogIn')
      this.login = false;
       
    }
    else {
      this.login = true;
    }


    var loged =  localStorage.getItem('loged')
    
    if(loged == null || loged == ''){
      //localStorage.clear()
    }
   
    this.idRestaurante = parseInt(localStorage.getItem('storeId'))
    this.baseOperacion =JSON.parse( localStorage.getItem('baseOperacion'))

    console.log('idRestaurante',this.idRestaurante)
    console.log('baseOperacion',this.baseOperacion)

    this.componentes = this.dataServices.getMenuOpts();
  }
  logOut(){
    localStorage.removeItem('loged');
    this.login = false;
    this.router.navigate(['/login']);
  }
}
