import { Injectable } from '@angular/core';
import { Componente } from '../Interfaces/interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, of, throwError ,} from 'rxjs';
import { retry,catchError,shareReplay,retryWhen, delay, take,mergeMap} from 'rxjs/operators';
import { ListaOrdenesModel } from '../modelos/listaordenesModel';
import { environment } from 'src/environments/environment';
import { StatusModel } from '../modelos/statusModel';
import { DetalleOrdenModel } from '../modelos/detalleOrdenModel';
import { DriversModel } from '../modelos/driversModel';
import { LiquidacionesModel } from '../modelos/liquidacionesModel';
import { LiquidacionTodoModel } from '../modelos/liquidacionTodoModel';

@Injectable({
  providedIn: 'root' 
})
export class GeneralService {
  
  public listaOrders :any[] = new Array();
  private statusLoging = JSON.parse(localStorage.getItem('loged') || 'false')
  private dateloged = new Date(localStorage.getItem('logeddate') ||null)
  headers = new HttpHeaders({'Content-Type': 'application/json'});
  readonly apiurl = environment.API_URL + '/api/';
  readonly api_reporteria = environment.API_Reporteria + '/api/';
  readonly api_dashboard = environment.API_URL_DashBoard + '/api/';
  constructor(private http: HttpClient) { }

 SetIsLoging(value: boolean,date ) {
    this.statusLoging = value
    this.dateloged = date
    
    localStorage.setItem('loged', value.toString())
    localStorage.setItem('logeddate',date)
  }

  GetisLoggedIn() {
    return JSON.parse(localStorage.getItem('loged') || this.statusLoging.toString())
  }
  getDateLoged() {
    return  new Date(localStorage.getItem('logeddate') || this.dateloged.toString())
  }

  public postLogin(model: any): Observable<any> {
    return this.http.post<any>(this.apiurl + 'Login/LoginMain', model, {headers: this.headers});
  }
  public createLogin(model: any): Observable<any> {
    return this.http.post<any>(this.apiurl + 'Login/CreatePassword', model, {headers: this.headers});
  }

  getMenuOpts() {
    return this.http.get<Componente[]>('/assets/info/menus.json');
  }

  getEstadosOpts() {
    return this.http.get<Componente[]>('/assets/info/estados.json');
  }

  // Lista Ordenes

  
  public getListaOrdenes(id: any): Observable<ListaOrdenesModel[]> {
    return this.http.get<ListaOrdenesModel[]>(this.apiurl + 'Order/OrdersList' + '/' + id, {headers: this.headers})
  }
  public getListaOrdenesListado(id: any): Observable<ListaOrdenesModel[]> {
    return this.http.get<ListaOrdenesModel[]>(this.apiurl + 'Order/OrdersListado' + '/' + id, {headers: this.headers})
  }

  public ordenesDemora(id,base3): Observable<any> {
      return this.http.get<any>(
          this.api_dashboard + "demoraOrdenesRest" + "/" + id+ "/" + base3
      );
  }
  public getOrdenesDemora(id, baseo): Observable<any[]> {
    return this.http.get<any[]>(this.api_dashboard + 'OrdenesDemoraRest' + '/' + id + '/' + baseo)
  }
  public getOrdenSearch(id: any,orderId: any): Observable<ListaOrdenesModel[]> {
    return this.http.get<ListaOrdenesModel[]>(this.apiurl + 'Order/OrdersSearch' + '/' + id + '/' + orderId, {headers: this.headers})
  }
  public getListaOrdenesCanceladas(id: any): Observable<ListaOrdenesModel[]> {
    return this.http.get<ListaOrdenesModel[]>(this.apiurl + 'Order/Canceladas' + '/' + id, {headers: this.headers})
  }

  public getDrivers(): Observable<DriversModel[]> {
    return this.http.get<DriversModel[]>(this.apiurl + 'Liquidaciones/Drivers', {headers: this.headers})
  }
  public getDriversRest(id:any): Observable<DriversModel[]> {
    return this.http.get<DriversModel[]>(this.apiurl + 'Liquidaciones/DriversRest' + '/' + id, {headers: this.headers})
  }
  public getLiquidacionesFinalizadas(id:any): Observable<LiquidacionesModel[]> {
    return this.http.get<LiquidacionesModel[]>(this.apiurl + 'Liquidaciones/Finalizadas'+ '/' + id, {headers: this.headers})
  }
  public getLiquidacionesXDriver(id:any): Observable<any[]> {
    return this.http.get<any[]>(this.apiurl + 'Liquidaciones/DetalleLiquidacionesXDriver'+ '/' + id, {headers: this.headers})
  }
  public getDetalleLiquidacion(id:any): Observable<LiquidacionesModel> {
    return this.http.get<LiquidacionesModel>(this.apiurl + 'Liquidaciones/DetalleLiquidacion'+ '/' + id, {headers: this.headers})
  }
  public getDetalleLiquidacionLista(id:any): Observable<LiquidacionesModel[]> {
    return this.http.get<LiquidacionesModel[]>(this.apiurl + 'Liquidaciones/DetalleLiquidacionLista'+ '/' + id, {headers: this.headers}) 
    
  }
  
  public getDetalleOrden(id: any): Observable<any> {
    return this.http.get<any>(this.apiurl + 'Order/OrderDetail' + '/' + id, {headers: this.headers})
  }
  public getStatus(id: any): Observable<StatusModel[]> {
    return this.http.get<StatusModel[]>(this.apiurl + 'Order/GetStatus' + '/' + id, {headers: this.headers})
  }

  public ChangeEstatus(empaque: any): Observable<any> {
    return this.http.post<any>(this.apiurl + 'Order/ChangeStatus' , empaque, {headers: this.headers});
  }
  // pos liquidacion
    public postLiquidacionEfectivo(liquidacion: LiquidacionesModel): Observable<LiquidacionesModel> {
      return this.http.post<LiquidacionesModel>(this.apiurl + 'Liquidaciones/LiquidarOrdenEfectivo', liquidacion, { headers: this.headers });
    }
    public postLiquidacionTarjeta(liquidacion: LiquidacionesModel): Observable<LiquidacionesModel> {
      return this.http.post<LiquidacionesModel>(this.apiurl + 'Liquidaciones/LiquidarOrdenTarjeta', liquidacion, { headers: this.headers });
    }
    public postSendPosGc(liquidacion: LiquidacionesModel): Observable<LiquidacionesModel> {
      return this.http.post<LiquidacionesModel>(this.apiurl + 'Liquidaciones/SendSettlement', liquidacion, { headers: this.headers });
    }

  // REPORTERIA
  getReporteCierre(fechaInical , fechaFinal,storeName:any, storeId:any) {
   // console.log(this.apiurl + 'ReportePrueba' + '/' + id);
   return this.http.get(this.api_reporteria + 'backoffice/reportecierre/' +  fechaInical + '/' + fechaFinal + '/' + storeName+ '/' + storeId)
  }
  getReporteCierreEfectivo(fechaInical , fechaFinal,storeName:any, storeId:any) {
   // console.log(this.apiurl + 'ReportePrueba' + '/' + id);
   return this.http.get(this.api_reporteria + 'backoffice/reporteefectivo/' +  fechaInical + '/' + fechaFinal + '/' + storeName+ '/' + storeId) 
  }
  getReporteLiquidacionFinal(id :any,storeName:any, storeId:any) {
    return this.http.get(this.api_reporteria + 'restaurante/liquidacionfinal/' +  id+ '/' + storeName+ '/' + storeId )
  }
  getReporteMisOrdenes(fechaInical , fechaFinal,storeName:any, storeId:any) {
    // console.log(this.apiurl + 'ReportePrueba' + '/' + id);
    return this.http.get(this.api_reporteria + 'restaurante/misOrdenes/' +  fechaInical + '/' + fechaFinal + '/' + storeName+ '/' + storeId)
   }

  getReporteMisDeliveries(  storeId:any,storeName:any) {
    return this.http.get(this.api_reporteria + 'restaurante/misdeliveries/' +  storeId+ '/' + storeName )
  }

  getReportePendienteBase(fechaInical ,storeName:any, storeId:any) {
    // console.log(this.apiurl + 'ReportePrueba' + '/' + id);
    return this.http.get(this.api_reporteria + 'backoffice/liquidacionXBase/' +  fechaInical+ '/' + fechaInical + '/' + storeName + '/' + storeId)
   }
  getReportePendienteRest(fechaInical ,storeName:any, storeId:any) {
    // console.log(this.apiurl + 'ReportePrueba' + '/' + id);
    return this.http.get(this.api_reporteria + 'backoffice/liquidacionXRest/' +  fechaInical+ '/' + fechaInical + '/' + storeName + '/' + storeId)
   }
  validateStore(store) {
    // console.log(this.apiurl + 'ReportePrueba' + '/' + id);
    return this.http.get(this.apiurl + 'Stores/ValidateStore/' +  store)
   }


  public getBrands(): Observable<any[]> {
    return this.http.get<any[]>(this.apiurl + 'Brands/Brands', {headers: this.headers}) 
  }

  public getStores(): Observable<any[]> {
    
    return this.http.get<any[]>(this.apiurl + 'Stores/Stores', {headers: this.headers})
  }

  

  getReporteDriversActivos(fechaInical, cityId, tipo: any, rest) {
    // console.log(this.apiurl + 'ReportePrueba' + '/' + id);
    return this.http.get(
        this.api_reporteria +
            "backoffice/reporteActivosDrivers/" + fechaInical + "/" + cityId + "/" + tipo + "/" + rest
    )
}

getReporteDriversActivosComanda(fechaInical, cityId, tipo: any, rest) {
    // console.log(this.apiurl + 'ReportePrueba' + '/' + id);
    return this.http.get(
        this.api_reporteria +
            "backoffice/reporteActivosDriversComanda/" + fechaInical + "/" + cityId + "/" + tipo + "/" + rest
    )
}

public requestCancelation(empaque: any): Observable<any> {
  return this.http.post<any>(this.apiurl + 'Order/RequestCancelationOrder' , empaque, {headers: this.headers});
}

public test(id): Observable<any> {
  return this.http.get<any>(this.apiurl + 'Order/FibaseInsert/'+ id);
}
public test2(id): Observable<any> {
  return this.http.get<any>(this.apiurl + 'Order/FirebaseUpdate/'+ id);
}

}
